import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const API_URL = 'http://aieasyread.com/api/ai-recommendations'; // Updated URL

function App() {
  const [loading, setLoading] = useState(false);
  const [bookTitle, setBookTitle] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [amazonAffiliateId, setAmazonAffiliateId] = useState('162483-20');
  const [typingText, setTypingText] = useState('');
  const [typingIndex, setTypingIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [cursor, setCursor] = useState('|');
  const texts = ['day?', 'month?', 'year?'];

  useEffect(() => {
    const handleTyping = () => {
      let currentIndex = typingIndex;
      let currentText = texts[currentIndex];
      let updatedText = typingText;

      if (isDeleting) {
        updatedText = currentText.substring(0, updatedText.length - 1);
      } else {
        updatedText = currentText.substring(0, updatedText.length + 1);
      }

      setTypingText(updatedText);

      if (!isDeleting && updatedText === currentText) {
        setTimeout(() => setIsDeleting(true), 2000);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setTypingIndex((currentIndex + 1) % texts.length);
      }
    };

    const typingInterval = setInterval(handleTyping, isDeleting ? 200 : 150);
    return () => clearInterval(typingInterval);
  }, [typingText, isDeleting, typingIndex]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setCursor((prev) => (prev === '|' ? '' : '|'));
    }, 500);
    return () => clearInterval(cursorInterval);
  }, []);

  const getRecommendations = async () => {
  setLoading(true);
  try {
    const response = await axios.post('/api/ai-recommendations', { bookTitle });
    const newRecommendations = response.data.recommendations.filter(newRec => {
      return !recommendations.some(existingRec => existingRec.title === newRec.title && existingRec.author === newRec.author);
    });
    setRecommendations([...recommendations, ...newRecommendations]);
  } catch (error) {
    console.error('Error fetching recommendations:', error);
  } finally {
    setLoading(false);
  }
};
  return (
    <div className="App">
      <div className="content">
        <h1>
          How many books can you read in a {typingText}<span className="cursor">{cursor}</span>
        </h1>
        <div className="input-group">
          <input
            type="text"
            value={bookTitle}
            onChange={(e) => setBookTitle(e.target.value)}
            placeholder="Book Title or Topic"
          />
          <button onClick={getRecommendations} disabled={loading}>Get Recommendations</button>
        </div>
        {loading && <div className="loading-message">AI is loading your book recommendations...</div>}
        <div className="recommendations">
          {recommendations.map((rec, index) => (
            <div key={index} className="book">
              <a href={`https://www.amazon.com/s?k=${encodeURIComponent(rec.title)}+${encodeURIComponent(rec.author)}&tag=${amazonAffiliateId}`} target="_blank" rel="noopener noreferrer">
                <img src={rec.cover} alt={`${rec.title} cover`} />
              </a>
              <p>
                <strong>{rec.title}</strong><br />
                {rec.author}
              </p>
            </div>
          ))}
        </div>
        {recommendations.length > 0 && (
          <button className="load-more" onClick={getRecommendations} disabled={loading}>Load More</button>
        )}
      </div>
    </div>
  );
}

export default App;
